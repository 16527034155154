import React from "react";
import { LandingPageMainWrapper, PageBody, WalletButtonsGroupWrapper } from "./view-order-main.style";
import { Footer } from "../../../components/footer";
import { PageBackgroundOverlay } from "../../../components/page-background-overlay/page-background-overlay";
import { HeaderToolbar } from "../../../components-business/header-toolbar/header-toolbar";
import { Row, Column } from "../../../../src/components/direction";
import { color } from "../../../theme";
import { LabelLarger, LabelLarge } from "../../../components/label";
import { ButtonPrimary } from "../../../components/buttons/button-primary";
import { ButtonDefault } from "../../../components/buttons/button-default";
import { RouteComponentProps } from "react-router";
import { AddressHeaderCell, TokenHeaderCell, BalanceHeaderCell, ActionBodyCell, ActionBodyColumn, ActionHeaderCell, HeaderRow, BodyRow, AddressBodyColumn, AddressBodyCell, TokenBodyColumn, BalanceBodyColumn, TokenBodyCell, BalanceBodyCell } from "./view-order-main.style";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ModalPrimary } from "../../../components/modal/modal-primary";
import { ModalFrame, ModalHeader, ModalTitle, ModalBody, ResetButton, FormLabel } from "./view-order-modal.style";
import { Icon } from "../../../components/icon";
import refreshIcon from '../../../components/icon/resources/refresh.png';
import { element, any, string } from "prop-types";
import { getOrdersByAccount, saveOrdersToAccount, getAccountId } from "../../../utils/localStorage/local-storage-helper";
import { tinyBarsToHBarsUnit, tinyBarsToHBarsCurr} from '../../../../src/utils/conversion/convertors';
import { address } from '../../../utils/address'
import { ButtonSelectToken } from "../../wallet-pages/wallet-main-page/wallet-main-page.style";

export class ViewOrder extends React.PureComponent<RouteComponentProps, {}> {
  state = {
    allOrderDetails: [],
    showOrdersModal: false,
    orderElement: {
      orderContractAddress: null,
      orderPrice: null,
      orderQuantity: null,
      orderReference: null,
      orderSide: null,
      orderType: null,
      Status: 'NEW',
      tokenBalance: null,
      hbarBalance: null,
      ProcessedQuantity: null,
      LastMatchedQuantity: null,
      AveragePrice: null,
      LastMatchedPrice: null,
      tokenId:null,
      tokenAddress:null,
    },
    orderIndex: null,
    filteredArray: []
  }
  
  constructor(props) {
    super(props);
    console.log('Props::::::::', this.props.match.params["address"]);
  }

  componentDidMount = async () => {
    //TO-DO RAHUL
    this.setState({ allOrderDetails: getOrdersByAccount() },()=>{
      this.filteredArray()
    })
  }

  PageDescription = () => {
    return (
      <Column style={{ marginTop: 50 }}>
        <LabelLarger style={{ textAlign: "center", alignSelf: "center", fontWeight: "bolder" }}>Welcome to AOChain Open Wallet </LabelLarger>
        <LabelLarger style={{ textAlign: "center", alignSelf: "center", fontWeight: "bold" }}>To begin, make sure you've paired your Hashgraph wallet with the Composer extension</LabelLarger>
        <LabelLarge style={{ textAlign: "center", alignSelf: "center", maxWidth: "72%", marginTop: 30 }}>HBAR Hot wallet is a zero client solution. Connection to Hashgraph network is made via Composer / Relay Network. All keys are saved inside the browser and never sent over our servers. Use at your own risk.
        </LabelLarge>
      </Column>
    )
  }

  //Reclaim token
  _handleClickReclaimTokenActionButton = async (element, i) => {
    const hash = (window as any).hash;
    if (hash) {
      let contractTokenAddress = this.props.match.params["address"];
      let contractId = element.orderContractAddress;
      let tokenAddr = hash.accountIdToEthAddress(address[contractTokenAddress].SMART_CONTRACT).toString();
      let value = parseInt(element.tokenBalance);
      if (contractId) {
        let data = {
          contractid: contractId,
          memo: "withdrawToken_Reclaim",
          paymentserver: "https://mps.hashingsystems.com",
          params: `["${tokenAddr}" , "${value}"]`,
          amount: 0,
          abi: `[{
            "constant": false,
            "inputs": [
                {
                    "name": "token",
                    "type": "address"
                },
                {
                    "name": "value",
                    "type": "uint256"
                }
            ],
            "name": "withdrawToken",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        }]`,
        }
        hash.triggerSmartContract(data)
          .then(res => {
            if (res.code == 200) {
              //TO-DO RAHUL
              const modifiedData: any = this.state.allOrderDetails;
              let _indexOfSingleData = 0;
              modifiedData.map((e: any, i: number) => {
                if (e.orderContractAddress === contractId) {
                  _indexOfSingleData = i;
                }
              })
              const modifiedSingleData: any = this.state.allOrderDetails[_indexOfSingleData];
              modifiedSingleData.tokenBalance = 0;
              modifiedData.splice(_indexOfSingleData, 1, modifiedSingleData)
              this.setState({ orderElement: Object.assign({}, modifiedSingleData) })
              saveOrdersToAccount(modifiedData);
              Swal.fire({
                title: 'Success',
                text: 'Token Reclaimed Successfully.',
                type: 'success',
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Something went wrong',
                type: 'error',
                confirmButtonText: 'Ok',
              })
            }
          })
          .catch(err => {
            const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
            Swal.fire({
              title: 'Error',
              text: 'Error : ' + msg,
              type: 'error',
              confirmButtonText: 'Ok',
            })
          })
      }
    }
  }


  //Reclaim Hbar
  _handleClickReclaimHbarActionButton = async (element, i) => {
    const hash = (window as any).hash;
    if (hash) {
      let value = Number(element.hbarBalance);
      let contractId = element.orderContractAddress;
      if (contractId) {
        let data = {
          contractid: contractId,
          memo: "withdrawHbars_Reclaim",
          paymentserver: "https://mps.hashingsystems.com",
          params: `[${value}]`,
          amount: 0,
          abi: `[{
        "constant": false,
        "inputs": [
            {
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "withdrawHbars",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }]`,
        }
        hash.triggerSmartContract(data)
          .then(res => {
            if (res.code == 200) {
              //TO-DO RAHUL
              const modifiedData: any = this.state.allOrderDetails;
              let _indexOfSingleData = 0;
              modifiedData.map((e: any, i: number) => {
                if (e.orderContractAddress === contractId) {
                  _indexOfSingleData = i;
                }
              })
              const modifiedSingleData: any = this.state.allOrderDetails[_indexOfSingleData];              modifiedSingleData.hbarBalance = 0;
              modifiedData.splice(_indexOfSingleData, 1, modifiedSingleData)
              this.setState({ orderElement: Object.assign({}, modifiedSingleData) })
              saveOrdersToAccount(modifiedData);
              Swal.fire({
                title: 'Success',
                text: 'Hbars Reclaimed Successfully.',
                type: 'success',
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Something went wrong',
                type: 'error',
                confirmButtonText: 'Ok',
              })
            }
          })
          .catch(err => {
            const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
            Swal.fire({
              title: 'Error',
              text: 'Error : ' + msg,
              type: 'error',
              confirmButtonText: 'Ok',
            })
          })
      }
    }
  }

  //Deciding reclaim for Hbar or token
  _handleClickReclaimActionButton = async (element, i) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
    if (!result.dismiss) {
      if (element.orderSide == 0) {
        this._handleClickReclaimHbarActionButton(element, i)
      }
      else {
        this._handleClickReclaimTokenActionButton(element, i)
      }
    }
  }

  //Cancel transaction
  _handleClickCancelActionButton = async (element, i) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
    if (!result.dismiss) {
      let tokenAddress = this.props.match.params["address"];
      const hash = (window as any).hash
      if (hash) {
        debugger;
        let _order = hash.accountIdToEthAddress(element.orderContractAddress);
        let _cancel = true;
        let i_quantity = 0;
        let i_price = 0;
        let i_newClOrdId = element.orderReference;
        let contractId = address[tokenAddress].EXCHANGE;
        let data = {
          contractid: contractId,
          memo: "editOrder_Cancel",
          paymentserver: "https://mps.hashingsystems.com",
          params: `["${_order}", ${_cancel},${i_quantity},${i_price},"${i_newClOrdId}"]`,
          amount: 0,
          abi: `[{
        "constant": false,
        "inputs": [
            {
                "name": "_order",
                "type": "address"
            },
            {
                "name": "_cancel",
                "type": "bool"
            },
            {
                "name": "i_quantity",
                "type": "uint256"
            },
            {
                "name": "i_price",
                "type": "uint256"
            },
            {
                "name": "i_newClOrdId",
                "type": "string"
            }
        ],
        "name": "editOrder",
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
    }]`,
        }
        hash.triggerSmartContract(data)
          .then(res => {
            if (res.code == 200) {
              //TO-DO RAHUL
              const modifiedData: any = this.state.allOrderDetails;
              let _indexOfSingleData = 0;
              modifiedData.map((e: any, i: number) => {
                if (e.orderContractAddress === element.orderContractAddress) {
                  _indexOfSingleData = i;
                }
              })
              const modifiedSingleData: any = this.state.allOrderDetails[_indexOfSingleData];
              modifiedSingleData.Status = res.result[0] === true ? 'CANCELLED' : '';

              modifiedData.splice(_indexOfSingleData, 1, modifiedSingleData);
              this.setState({ orderElement: Object.assign({}, modifiedSingleData)})
              saveOrdersToAccount(modifiedData);
              Swal.fire({
                title: 'Success',
                text: 'Order Cancelled Successfully',
                type: 'success',
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Something went wrong',
                type: 'error',
                confirmButtonText: 'Ok',
              })
            }
          })
          .catch(err => {
            const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
            Swal.fire({
              title: 'Error',
              text: 'Error : ' + msg,
              type: 'error',
              confirmButtonText: 'Ok',
            })
          })
      }
    }
  }

  //Handle order modal popup visibility
  _handleShowCloseOrderModal(element, i) {
    this.setState({ showOrdersModal: !this.state.showOrdersModal, orderElement: element, orderIndex: i },()=>{
      this.setState({ allOrderDetails: getOrdersByAccount() }, () => {
        this.filteredArray()
      })
    })
  }

  //Returning code for order modal popup
  orderModal() {
    const orderElement = this.state.orderElement;
    const orderIndex = this.state.orderIndex;

    return (
      <ModalPrimary className="modal-select-tokens" visible={this.state.showOrdersModal}>
        <ModalFrame>
          <ModalHeader>
            <ModalTitle>Order Details</ModalTitle>
            <Icon iconType="close" style={{ width: 15, height: 15 }} onClick={() => this._handleShowCloseOrderModal(null, null)} />
          </ModalHeader>
          <ModalBody style={{ padding: '10px 30px', position: 'relative' }}>
            <img src={refreshIcon} onClick={() => this._getOrderBalance(orderElement, orderIndex)} style={{ maxWidth: '25px', position: 'absolute', right: '25px', cursor: 'pointer', marginTop: '4px' }} />
            <FormLabel className="section1" style={{ flex: 2, fontSize: '20px', textDecoration: 'underline', fontWeight: 'bold', }}>Detail Info:</FormLabel>
            <Row style={{ marginTop: '10px' }}>
              <Column>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Order Address:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Order Type:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}> Order Slide:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Order Quantity:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Order Price:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Order Reference:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Token Code:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Solidity Address:</FormLabel>
              </Column>
              <Column style={{ marginLeft: '30px' }}>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.orderContractAddress}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.orderType == 0 ? 'Limit Order' : 'Market Order'}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.orderSide == 0 ? 'Buy/Bid' : 'Sell/Ask'}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.orderQuantity}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{`${orderElement && orderElement.orderPrice} ℏ`}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, borderRight: '0' }}>{orderElement && orderElement.orderReference}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, borderRight: '0' }}>{orderElement && orderElement.tokenId}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, borderRight: '0' }}>{orderElement && orderElement.tokenAddress}</FormLabel>
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <FormLabel className="section1" style={{ flex: 1, borderBottom: '0', fontWeight: 'bold' }}>Status:</FormLabel>
              <FormLabel className="section1" style={{ flex: 6, borderRight: '0', borderBottom: '0' }}>{orderElement && orderElement.Status}</FormLabel>
            </Row>
            <FormLabel className="section1" style={{ flex: 2, fontSize: '20px', textDecoration: 'underline', fontWeight: 'bold', marginTop: '20px' }}>Processing Info:</FormLabel>
            <Row style={{ marginTop: '10px' }}>
              <Column>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Original Quantity:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Processed Quantity:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Last Matched Quantity:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Average Price:</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, fontWeight: 'bold' }}>Last Matched Price:</FormLabel>
              </Column>
              <Column style={{ marginLeft: '30px' }}>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.orderQuantity}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.ProcessedQuantity}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && orderElement.LastMatchedQuantity}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && tinyBarsToHBarsCurr(Number(orderElement.AveragePrice))}</FormLabel>
                <FormLabel className="section1" style={{ flex: 2, }}>{orderElement && tinyBarsToHBarsCurr(Number(orderElement.LastMatchedPrice))}</FormLabel>
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <FormLabel className="section1" style={{ flex: 2, borderBottom: '1', fontWeight: 'bold' }}>Token Balance:</FormLabel>
              <FormLabel className="section1" style={{ flex: 7, borderRight: '0', borderBottom: '1', fontWeight: 'bold' }}>Hbar Balance:</FormLabel>
            </Row>
            <Row style={{}}>
              <FormLabel className="section1" style={{ flex: 2, borderBottom: '0' }}>{orderElement && orderElement.tokenBalance}</FormLabel>
              <FormLabel className="section1" style={{ flex: 7, borderRight: '0', borderBottom: '0' }}>{orderElement && tinyBarsToHBarsCurr(Number(orderElement.hbarBalance))}</FormLabel>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
              <ButtonPrimary className={!this.getCancelButtonVisibility(orderElement) ? 'opacityHalf' : ''} disabled={!this.getCancelButtonVisibility(orderElement)} onClick={() => this._handleClickCancelActionButton(orderElement, orderIndex)} style={{ zIndex: 1, background: 'rgba(241, 112, 103, 1)', display: 'flex', color: 'white', justifyContent: 'center', width: '30%' }} >
                Cancel Order
                      </ButtonPrimary>
              <ButtonDefault className={!this.getReclaimButtonVisibility(orderElement) ? 'opacityHalf' : ''} disabled={!this.getReclaimButtonVisibility(orderElement)} onClick={() => this._handleClickReclaimActionButton(orderElement, orderIndex)} style={{ zIndex: 1, display: 'flex', justifyContent: 'center', width: '30%', background: 'rgb(56,185,212)', color: 'white', marginLeft: 10 }}>{orderElement && orderElement.orderSide == 0 ? 'Reclaim Hbar' : 'Reclaim Token'}
              </ButtonDefault>
            </Row>
          </ModalBody>
        </ModalFrame>
      </ModalPrimary >
    )
  }

  //Cancel button visibility
  getCancelButtonVisibility(orderElement) {
    if (orderElement && orderElement.Status === "NEW" || orderElement && orderElement.Status === "ACCEPTED" || orderElement && orderElement.Status === "FILLING") {
      return true
    }
    return false;
  }

  //Reclaim button visibility
  getReclaimButtonVisibility(orderElement) {
    if (orderElement && (orderElement.Status === "CANCELLED" || orderElement && orderElement.Status === "FILLED") && (orderElement.tokenBalance > 0 || orderElement.hbarBalance > 0)) {
      return true
    }
    return false;
  }

  //Delete button visibility
  getDeleteButtonVisibility(orderElement) {
    if (orderElement && ((orderElement.Status === "CANCELLED" && !this.getReclaimButtonVisibility(orderElement)) || orderElement && orderElement.Status === "FILLED")) {
      return true
    }
    return false;
  }

  //Order status and balance
  _getOrderBalance = async (element, i) => {
    const hash = (window as any).hash;
    if (hash) {
      //Order Reader
      let contractTokenAddress = this.props.match.params["address"];
      let contractId = address[contractTokenAddress].READER;
      let orderAddress = hash.accountIdToEthAddress(element.orderContractAddress).toString();
      let tokenAddress = hash.accountIdToEthAddress(address[contractTokenAddress].SMART_CONTRACT).toString();
      let data = {
        contractid: contractId,
        memo: "getOrderBalance",
        paymentserver: "https://mps.hashingsystems.com",
        params: `["${orderAddress}" , "${tokenAddress}"]`,
        amount: 0,
        abi: `[{
          "constant": true,
          "inputs": [
            {
              "name": "_order",
              "type": "address"
            },
            {
              "name": "token",
              "type": "address"
            }
          ],
          "name": "getOrderBalance",
          "outputs": [
            {
              "name": "_cumQuantity",
              "type": "uint256"
            },
            {
              "name": "_avgPrice",
              "type": "uint256"
            },
            {
              "name": "_lastQuantity",
              "type": "uint256"
            },
            {
              "name": "_lastPrice",
              "type": "uint256"
            },
            {
              "name": "_balance",
              "type": "uint256"
            },
            {
              "name": "_tknBalance",
              "type": "uint256"
            },
            {
              "name": "_orderStatus",
              "type": "string"
            }
          ],
          "payable": false,
          "stateMutability": "view",
          "type": "function"
        }]`,
      }
      hash.triggerSmartContract(data)
        .then(res => {
          if (res.code == 200) {
            //TO-DO RAHUL
            const modifiedData: any = this.state.allOrderDetails;
            let _indexOfSingleData = 0;
            modifiedData.map((e: any, i: number) => {
              if (e.orderContractAddress == element.orderContractAddress) {
                _indexOfSingleData = i;
              }
            })
            const modifiedSingleData: any = this.state.allOrderDetails[_indexOfSingleData];
            modifiedSingleData.tokenBalance = res.result[5];
            modifiedSingleData.hbarBalance = res.result[4];
            modifiedSingleData.Status = res.result[6];
            modifiedSingleData.ProcessedQuantity = res.result[0];
            modifiedSingleData.LastMatchedQuantity = res.result[2];
            modifiedSingleData.AveragePrice = res.result[1];
            modifiedSingleData.LastMatchedPrice = res.result[3];
            
            modifiedData.splice(_indexOfSingleData, 1, modifiedSingleData)
            this.setState({ orderElement: Object.assign({}, modifiedSingleData), allOrderDetails: modifiedData.map(a => Object.assign({}, a)) })
            saveOrdersToAccount(modifiedData);
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong',
              type: 'error',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch(err => {
          const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
          Swal.fire({
            title: 'Error',
            text: 'Error : ' + msg,
            type: 'error',
            confirmButtonText: 'Ok',
          })
        })
    }
  }

  //Deleting the order
  _handleClickDeleteActionButton(element, i){
    let accountId = getAccountId();
    const removeNewArray : any = this.state.allOrderDetails.filter((e : any) => {
      return e.orderContractAddress !== element.orderContractAddress;
    });
    saveOrdersToAccount(removeNewArray)
    this.setState({ allOrderDetails: getOrdersByAccount() }, () => {
      this.filteredArray()
    })
  }

  //Filter order list by token address
 filteredArray(){
 let dataArray = this.state.allOrderDetails;
   let newArray: any = dataArray && dataArray.filter( (el: any) => {
   return (el.tokenAddress == this.props.match.params["address"]);
 });
 this.setState({filteredArray: newArray})
}

  render() {
    let contractTokenAddress = this.props.match.params["address"];
    console.log('render calling::::::::', contractTokenAddress, address[contractTokenAddress].READER)
    return (
      <LandingPageMainWrapper className="landing-page-main">
        {this.orderModal()}
        <HeaderToolbar />
        <PageBackgroundOverlay />
        <PageBody>
          <Column style={{ marginTop: 80, alignSelf: "center", minHeight: 50, marginLeft: 50, marginRight: 50, marginBottom: 50 }}>
            <h1 style={{ color: 'rgb(56, 185, 212)' }}>Trade Orders for {contractTokenAddress}</h1>
            {this.state.allOrderDetails && this.state.filteredArray.length > 0 ?
              <div>
                <HeaderRow style={{ background: '#38b9d4' }}>
                  {/* <IconHeaderCell>Icon</IconHeaderCell> */}
                  <TokenHeaderCell className="headerTitle">Order Address</TokenHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Type</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Side</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Quantity</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Price (ℏ)</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Avg Price (ℏ)</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Processed Qty</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Status</AddressHeaderCell>
                  <AddressHeaderCell className="headerTitle">Order Reference</AddressHeaderCell>
                  <ActionHeaderCell className="headerTitle">Action</ActionHeaderCell>
                </HeaderRow>
                {this.state.filteredArray.map((element: any, i) => (
                  <BodyRow>
                    <Column style={{ flex: 1 }}>
                      <Row className="row" onClick={() => this._handleShowCloseOrderModal(element, i)}>
                        <TokenBodyColumn>
                          <TokenBodyCell key={i}>
                            {element.orderContractAddress}
                          </TokenBodyCell>
                        </TokenBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.orderType == 0 ? 'Limit Order' : 'Market Order'}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.orderSide == 0 ? 'Buy/Bid' : 'Sell/Ask'}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.orderQuantity}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.orderPrice}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                         <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {tinyBarsToHBarsCurr(element.AveragePrice)}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.ProcessedQuantity}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn>
                          <AddressBodyCell key={i}>
                            {element.Status}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <AddressBodyColumn >
                          <AddressBodyCell key={i}>
                            {element.orderReference}
                          </AddressBodyCell>
                        </AddressBodyColumn>
                        <ActionBodyColumn>
                      <ActionBodyCell style={{background:'none'}}>
                            <ButtonPrimary onClick={(e) => {e.stopPropagation()
                          this._handleClickCancelActionButton(element, i)
                        }} 
                              className={!this.getCancelButtonVisibility(element) ? 'opacityHalf' : ''} disabled={!this.getCancelButtonVisibility(element)}
                        style={{ zIndex: 1, background: 'rgba(241, 112, 103, 1)', display: 'flex', color: 'white', justifyContent: 'center', minWidth:'auto', maxWidth:'70px' }} >
                              Cancel
                      </ButtonPrimary>
                            <ButtonPrimary  onClick={(e) => {
                              e.stopPropagation()
                              this._handleClickDeleteActionButton(element, i)
                            }}
                              className={!this.getDeleteButtonVisibility(element) ? 'opacityHalf' : ''} disabled={!this.getDeleteButtonVisibility(element)}
                              style={{ zIndex: 1, background: 'rgba(241, 112, 103, 1)', display: 'flex', color: 'white', justifyContent: 'center', minWidth: 'auto', marginLeft:'5px', maxWidth: '70px' }} >
                              Delete
                      </ButtonPrimary>
                            <img src={refreshIcon} onClick={(e) => { e.stopPropagation() 
                            this._getOrderBalance(element, i)}} style={{ maxWidth: '15px',marginLeft: '5px', cursor: 'pointer', marginTop: '4px' }} />
                      </ActionBodyCell>
                    </ActionBodyColumn>
                      </Row>
                    </Column>
                  </BodyRow>
                ))}
              </div> : <span className="noOrder">No Orders</span>}
          </Column>
          <ButtonSelectToken onClick={this._handleClickAddOrderButton} style={{ background:'rgb(56, 185, 212)'}}>Add Order Manually</ButtonSelectToken>
        </PageBody>
        <Footer />
      </LandingPageMainWrapper>
    );
  }
  _handleClickAddOrderButton = () => {
    //this.props.showAddTokensModal()
  }
}
