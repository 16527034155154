export const TokenData =
{
  name: "Wallet 1",
  address: "",
  accountID: "",
  listTokens: [
    {
      id: "HBAR",
      address: "0.0.0",
      icon: "icons/hbar_icon.png",
      name: "HBAR",
      balances: "0",
      usds: ""
    },
    {
      id: "MAINNET-OA-TEST001",
      address: "0.0.21930",
      icon: "icons/hbar_icon.png",
      name: "MAINNET-OA-TEST001",
      balances: {},
      usds: ""
    },
    {
      id: "JAM",
      address: "0.0.40941",
      icon: "",
      name: "JAM",
      balances: {},
      usds: ""
    }
  ]
}
