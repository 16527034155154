export const palette = {
  black: "#494949",
  white: "#ffffff",
  whiteOpacity: "rgba(232, 244, 250, 0.9)",
  lilyWhite: "#E4F8FF",
  offWhite: "#e6e6e6",
  orange: "#fe9d2b",
  orangeLight: "#FFA000",
  orangeDarker: "#EB9918",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
  drawerMenuBG: "#041824",
  pickerBackground: "#252525",
  borderPrimary: "#5aa1c2",
  lightBlue: "#003d63",
  blueBold: "#4B86B4",
  blueLight: "#62ACE5",
  greenLight: "#5ac1bc",
  greenBold: "#009688",
  brownLight: "#e3e4e4",
  grey: "#ECEDEE",
  greyLight: "#2C3B41",
  redLight: "#FE9C8F",
  purpleLight: "#A349A4",
  purpleBold: "#205081",
  redBold: "#D73925",
  brownBold: "#7b7b7b",
  yellowLight: "#FCF8E3"
}
