export const address = {
    "0.0.15242": {
        EXCHANGE: "0.0.15232",
        EXCHANGE_VERSION: 0,
        READER: "0.0.15234",
        SMART_CONTRACT: "0.0.15242",
        FEE: 0,
        QUANTITY: 1
    },
    "0.0.21930": {
        EXCHANGE: "0.0.15232",
        EXCHANGE_VERSION: 0,
        READER: "0.0.15234",
        SMART_CONTRACT: "0.0.21930",
        FEE: 1,
        QUANTITY: 1
    },
    "0.0.43634": {
        EXCHANGE: "0.0.43627",
        EXCHANGE_VERSION: 1,
        READER: "0.0.43629",
        SMART_CONTRACT: "0.0.43634",
        FEE: 1,
        QUANTITY: 1
    },
    "0.0.50171": { //testnet
        EXCHANGE: "0.0.50166",
        EXCHANGE_VERSION: 1,
        READER: "0.0.50168",
        SMART_CONTRACT: "0.0.50171",
        FEE: 1,
        QUANTITY: 1
    },
    "0.0.65076": { //testnet PhiCoin
        EXCHANGE: "0.0.50166",
        EXCHANGE_VERSION: 1,
        READER: "0.0.65095",
        SMART_CONTRACT: "0.0.65076",
        FEE: 10,
        QUANTITY: 10
    },
}
