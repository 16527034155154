import { ModalPrimary } from "../../../../../components/modal/modal-primary";
import React from "react";
import { Icon } from "../../../../../components/icon";
import { ModalFrame, ModalHeader, ModalTitle, ModalBody, FormSendToken, FormLabel, ResetButton } from "./section-modal-send-tokens.style";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps, ISectionStoreStateProps, ISectionStoreDispatchProps, ISectionOwnProps, ISectionModalsProps } from "./section-modal-send-tokens.props";
import { StoreRootState } from "../../../../../app-redux-store/root/root-reducer";
import { Row, Column } from "../../../../../components/direction";
import { TextInputOutline } from "../../../../../components/text-input/text-input-outline";
import { ButtonPrimary } from "../../../../../components/buttons/button-primary";
import { HederaPayment } from "./hedera-payment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { accountIdToEthAddress, hbarToTinyBar } from '../../../../../utils/conversion/convertors';

export class SectionModalSendTokensPureComponent extends React.Component<ISectionModalsProps, {}> {
  state = {
    paymentActivated: false,
    amtValue: 0,
    toAddressValue: ''
  }

  componentDidMount = async () => {
    const paymentActivated = await localStorage.getItem("paymentState")
    this.setState({
      paymentActivated: paymentActivated === "activated" ? true : false
    })
    if (paymentActivated === "activated") {
      this.props.showAppSnackBar()
    }
    await localStorage.setItem("paymentState", "inactivate")
  }

  handleInputChange(id, value) {
    switch (id) {
      case 'amtInput': {
        this.setState({ amtValue: value });
        break;
      }
      case 'toAddInput': {
        this.setState({ toAddressValue: value });
        break;
      }
    }
  }

  ActionButtonGroup = () => {
    return (
      <Column style={{ alignItems: "center", marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <ResetButton style={{ marginBottom: 10, marginRight: '10px', background: 'rgba(241, 112, 103, 1)', padding: '10px 110px', borderRadius: '8px', color: 'white', textDecoration: 'none' }}>Reset</ResetButton>
        <ButtonPrimary style={{ cursor: 'pointer', marginBottom: 10, marginLeft: '10px', width: 250, background: 'rgb(56, 185, 212)', color: 'white' }} onClick={this._handleClickCreateTransactionButton} disabled={this.checkValidation()}>Create Transaction</ButtonPrimary>
      </Column>
    )
  }

  // Amount Validation
  checkValidation() {
    let validFlag = true;
    if (!(this.state.amtValue > 0)) {
      validFlag = false;
    }
    if (!(this.state.toAddressValue.length > 0)) {
      validFlag = false;
    }
    return !validFlag;
  }

  render() {
    let TokenData: any = localStorage.getItem('tokenObj');
    TokenData = JSON.parse(TokenData);
    let tokenAccountID = '';
    if (TokenData && TokenData['listTokens'] && TokenData['listTokens'].length) {
      TokenData.listTokens.forEach((token, index) => {
        if (token.name == this.props.token) {
          tokenAccountID = token.address;
        }
      })
    }
    return (
      <ModalPrimary className="modal-select-tokens" visible={this.props.visible}>
        <ModalFrame>
          <ModalHeader style={{ backgroundImage: 'linear-gradient(to right, rgb(56, 185, 212), rgb(56, 185, 212))' }}>
            <ModalTitle>{this.props.source == tokenAccountID ? 'Crypto Transfer' : 'Send Tokens'}</ModalTitle>
            <Icon iconType="close" style={{ width: 15, height: 15 }} onClick={this._handleClickCloseIconButton} />
          </ModalHeader>
          <ModalBody>
            <FormSendToken>
              <Row style={{ justifyContent: "center", marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Source</FormLabel>
                <TextInputOutline style={{ flex: 8 }} value={this.props.source || TokenData.address} />
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Amount</FormLabel>
                <TextInputOutline type="number" style={{ flex: 3 }} id={`amtInput`} value={this.state.amtValue} onChange={(e) => this.handleInputChange(e.target.id, e.target.value)}></TextInputOutline>
                <FormLabel style={{ flex: 2, justifyContent: "center" }}>Token</FormLabel>
                <TextInputOutline style={{ flex: 3 }} value={this.props.token} />
              </Row>
              <TextInputOutline style={{ alignSelf: "stretch", marginTop: 15, marginBottom: 15 }} placeholder="Send to address" id={`toAddInput`} value={this.state.toAddressValue} onChange={(e) => this.handleInputChange(e.target.id, e.target.value)} />
              {/* <Row style={{ justifyContent: "center", marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ marginLeft: 0, marginRight: "auto" }}>Gas Price (HBAR)</FormLabel>
                <TextInputOutline type="number" defaultValue="10.00" />
              </Row> */}
            </FormSendToken>
          </ModalBody>
          <this.ActionButtonGroup />
        </ModalFrame>
        <HederaPayment activated={this.state.paymentActivated} />
      </ModalPrimary >
    )
  }

  _handleClickCloseIconButton = () => {
    this.props.hideSendTokensModal()
  }


  //Create Transaction
  _handleClickCreateTransactionButton = async () => {
    let amount = Number(this.state.amtValue);
    let toAddress = "";
    if (this.state.toAddressValue.length === 40) {
      toAddress = `"${this.state.toAddressValue}"`;
    } else {
      toAddress = `"${accountIdToEthAddress(this.state.toAddressValue)}"`;
    }

    const hash = (window as any).hash;
    if (hash) {
      let contractId;
      let TokenData: any = localStorage.getItem('tokenObj');
      TokenData = JSON.parse(TokenData);

      TokenData.listTokens.forEach(element => {
        if (this.props.token == element.name)
          contractId = element.address;
      });
      if (contractId) {
        const thisInstance = this;
        if (TokenData.accountID === contractId) {
          const account = thisInstance.state.toAddressValue;
          let data = {
            memo: "crypto transfer",
            paymentserver: "https://mps.hashingsystems.com",
            account: { contractId }, // account id of the "pay to" account
            contentid: "test1",
            redirect: '{"nonPayingAccount": "/nomicropaymentreceived.html"}',
            recipientlist: `[{"tinybars": "${hbarToTinyBar(amount)}", "to":"${account}"}]`,
            time: "1"
          }

          hash.triggerCryptoTransfer(data)
            .then(res => {
              if (res.receiptStatus == 22) {
                Swal.fire({
                  title: 'Success',
                  text: 'Token Transfered Successfully',
                  type: 'success',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  thisInstance._handleClickCloseIconButton();
                });
              } else if (res.receiptStatus == 10) {
                Swal.fire({
                  title: 'Warning',
                  text: 'Insufficient Balance',
                  type: 'warning',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  thisInstance._handleClickCloseIconButton();
                })

              } else if (res.receiptStatus == 28) {
                Swal.fire({
                  title: 'Warning',
                  text: 'Insufficient Balance',
                  type: 'warning',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  thisInstance._handleClickCloseIconButton();
                })

              } else {
                Swal.fire({
                  title: 'Error',
                  text: 'Error : Status Code - ' + res.receiptStatus,
                  type: 'error',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .catch(err => {
              const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
              Swal.fire({
                title: 'Error',
                text: 'Error Status Code: ' + msg,
                type: 'error',
                confirmButtonText: 'Ok',
              })
            })
        } else {
          let data = {
            contractid: contractId,
            memo: "token transfer",
            paymentserver: "https://mps.hashingsystems.com",
            params: `[${toAddress},${amount}]`,
            amount: 0,
            abi: `[{
            "constant": false,
            "inputs": [
              {
                "name": "_to",
                "type": "address"
              },
              {
                "name": "_value",
                "type": "uint256"
              }
            ],
            "name": "transfer",
            "outputs": [
              {
                "name": "success",
                "type": "bool"
              }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
          }]`,
          }
          hash.triggerSmartContract(data)
            .then(res => {
              if (res.code == 200) {
                Swal.fire({
                  title: 'Success',
                  text: 'Token Transfered Successfully',
                  type: 'success',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  thisInstance._handleClickCloseIconButton();
                });
              } else {
                Swal.fire({
                  title: 'Error',
                  text: 'Something went wrong',
                  type: 'error',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .catch(err => {
              const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
              Swal.fire({
                title: 'Error',
                text: 'Error Status Code: ' + msg,
                type: 'error',
                confirmButtonText: 'Ok',
              })
            })
        }
      }
    }
  }
}

export const SectionModalSendTokens = connect<ISectionStoreStateProps, ISectionStoreDispatchProps, ISectionOwnProps, StoreRootState>(
  mapStateToProps,
  mapDispatchToProps
)(SectionModalSendTokensPureComponent)