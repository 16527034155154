

export const getValueByKey = (key: string) => {
    return localStorage.getItem(key);
}

export const getTokenByAddr = (key: string, addr: string) => {
    let value: any = getValueByKey(key)
    if (value) {
        value = JSON.parse(value);
        if (value.listTokens && value.listTokens.length) {
            let dataObj: any = value.listTokens.filter((element: any) => {
                return element.address == addr
            })
            return dataObj;
        }
    } else {
        return null;
    }
}

export const saveToLocalStorage = (key: string, addr: string, balance: string) => {
    let value: any = getValueByKey(key)
    if (value) {
        value = JSON.parse(value);
        const currentAccountID = value.accountID;
        if (value.listTokens && value.listTokens.length) {
            value.listTokens.forEach(element => {
                if (element.address == addr) {
                    element.balances[currentAccountID] = balance
                }
            });
            localStorage.setItem(key, JSON.stringify(value));
        }
    } else {
        return null;
    }
}



export const getOrdersByAccount = () => {
    let tokenData: any = localStorage.getItem('tokenObj');
    if(tokenData) {
        tokenData = JSON.parse(tokenData);
        let value: any = getValueByKey('orders')
        if (value) {
            value = JSON.parse(value);
            console.log('Rahul getOrdersByAccount ', value)
            return value[tokenData.accountID];
        } else {
            return [];
        }
    }
    return []; 
}

export const saveOrdersToAccount = (orderData: any) => {
    let tokenData: any = localStorage.getItem('tokenObj');
    if(tokenData) {
        tokenData = JSON.parse(tokenData);
        let orders = getValueByKey('orders');
        if(orders) {
            let _orders = JSON.parse(orders);
            _orders[tokenData.accountID] = orderData;
            localStorage.setItem('orders', JSON.stringify(_orders));
        }
    }
}

export const getAccountId = () => {
    let tokenData: any = localStorage.getItem('tokenObj');
    if (tokenData) {
        tokenData = JSON.parse(tokenData);
        return tokenData.accountID;
    }
}

export const saveOrdersByAccount = (orderData: any) => {
    let tokenData: any = localStorage.getItem('tokenObj');
    if(tokenData) {
        tokenData = JSON.parse(tokenData);
        let orders = getValueByKey('orders');
        let value = JSON.parse(orders ? orders : '{}');
        console.log('Rahul saveOrdersByAccount ', value)
        let accOrders = value[tokenData.accountID] ? value[tokenData.accountID] : [];
        accOrders.push(orderData);
        value[tokenData.accountID] = accOrders;
        localStorage.setItem('orders', JSON.stringify(value));
    }
}