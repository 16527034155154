import React from "react"
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';

import { TextFieldProps } from '@material-ui/core/TextField';
import { FONT_PRIMARY, FONT_SIZE_MEDIUM } from "../../constants";
import { color } from "../../theme";

export const DropDown= styled((props: (any)) => {
    return (
        <TextField
            id={props.id || ''}
            select
            label="Select"
            style={{width:'80%'}}
            value={props.value && props.value}
            onChange={(evt) => { props.onChange && props.onChange(evt.target.value)}}
            margin="normal"
        >
            {props.list && props.list.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
})`
`