import { ModalPrimary } from "../../../../../components/modal/modal-primary";
import React from "react";
import { Icon } from "../../../../../components/icon";
import { ModalFrame, ModalHeader, ModalTitle, ModalBody, FormSendToken, FormLabel, ResetButton } from "./section-modal-trade-tokens.style";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps, ISectionStoreStateProps, ISectionStoreDispatchProps, ISectionOwnProps, ISectionModalsProps } from "./section-modal-trade-tokens.props";
import { StoreRootState } from "../../../../../app-redux-store/root/root-reducer";
import { Row, Column } from "../../../../../components/direction";
import { TextInputOutline } from "../../../../../components/text-input/text-input-outline";
import { ButtonPrimary } from "../../../../../components/buttons/button-primary";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { DropDown } from '../../../../../components/drop-down/drop-down';
import { accountIdToEthAddress, hbarToTinyBar } from '../../../../../utils/conversion/convertors';
import { saveOrdersByAccount, saveOrdersToAccount } from "../../../../../utils/localStorage/local-storage-helper";
import { address } from '../../../../../utils/address'

export class SectionModalTradeTokensPureComponent extends React.Component<ISectionModalsProps, {}> {

  initialState = {
    orderType: '',
    orderSide: '',
    orderQuantity: 0,
    orderPrice: 0,
    orderFee: 0,
    orderReference: ''
  }

  state = {
    name: '',
    address: '',
    buttonTxt: 'Save',
    visible: false,
    amount: '',
    isTokenApproved: false,
    ...this.initialState
  }

  orderType = [
    {
      value: '0',
      label: 'Limit Order',
    },
    {
      value: '1',
      label: 'Market Order',
    },
  ];

  orderSide = [
    {
      value: '0',
      label: 'Buy/Bid',
    },
    {
      value: '1',
      label: 'Sell/Ask',
    },
  ];

  componentWillReceiveProps = (nextProps: ISectionModalsProps) => {
    this.setState({ visible: nextProps.visible });
  }

  componentDidMount = async () => {
    setTimeout(() => {
      let banner = document.getElementById("hedera-banner-wrapper")
    }, 5000);
  }

  //set text Field value in state
  handleInputChange(id, value) {
    switch (id) {
      case 'orderQuantity': {
        this.setState({ orderQuantity: value });
        break;
      }
      case 'orderPrice': {
        this.setState({ orderPrice: value });
        break;
      }
      case 'orderFee': {
        this.setState({ orderFee: value });
        break;
      }
      case 'orderReference': {
        this.setState({ orderReference: value });
        break;
      }
    }
  }

  // order type drop down
  orderTypeSet(value) {
    // if(value == 1){
    //   this.setState({orderPrice: '0'})
    // }
    this.setState({ orderType: value })
  }

  // Order quantity with no decimal value
  handleChange(evt) {
    // console.log('handleChange ', evt.target.validity.valid)
    // console.log('handleChange ', evt.target.value)

    const orderQuantity = (evt.target.validity.valid) ? evt.target.value : this.state.orderQuantity;
    // console.log('handleChange ', orderQuantity)

    this.setState({ orderQuantity });
  }

  render() {
    let tokenAddress = this.props.tokenAddress;
    if (tokenAddress != ''){
      this.state.orderFee = address[tokenAddress].FEE;
      this.state.orderQuantity = address[tokenAddress].QUANTITY;
    }  

    if (this.state.orderType === '1' && this.state.orderSide === '1' && Number(this.state.orderPrice) > 0) {
      this.setState({ orderPrice: 0 })
    }
    console.log('processing...', this.props.tokenAddress)
    const isValid = this._validate();
    return (
      <ModalPrimary className="modal-select-tokens" visible={this.props.visible}>
        <ModalFrame>
          <ModalHeader>
            <ModalTitle>Create Order for {tokenAddress}</ModalTitle>
            <Icon iconType="close" style={{ width: 15, height: 15 }} onClick={this._handleClickCloseIconButton} />
          </ModalHeader>
          <ModalBody>
            <FormSendToken>
              <Row style={{ justifyContent: "center", marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Order Type</FormLabel>
                <DropDown style={{ flex: 8 }} list={this.orderType} onChange={(value) => this.orderTypeSet(value)} value={this.state.orderType} />
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Order Side</FormLabel>
                <DropDown style={{ flex: 8 }} list={this.orderSide} onChange={(value) => this.setState({ orderSide: value })} value={this.state.orderSide} />
              </Row>
              <Row style={{ justifyContent: "center", position: 'relative', marginTop: 15, marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Order Quantity</FormLabel>
                {/* <TextInputOutline id="orderQuantity" pattern="[0-9]*" onInput={this.handleChange.bind(this)} value={this.state.orderQuantity} type="number" style={{ flex: 3 }}></TextInputOutline> */}
                <input className="quantityBox" id="orderQuantity"  disabled={true} type="text" pattern="[0-9]*" onInput={this.handleChange.bind(this)} value={this.state.orderQuantity} style={{ padding: '12px 14px', borderRadius: '10px', fontWeight: 'bold', border: '1px solid #d8dcdc', fontSize: '13px', maxWidth: '105px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }} />
                <FormLabel style={{ flex: 2, justifyContent: "center" }}>Order Price</FormLabel>
                <TextInputOutline type="number" id="orderPrice" style={{ flex: 3, paddingRight: '25px' }} onChange={(e) => this.handleInputChange(e.target.id, e.target.value)}
                  readonly={this.state.orderType == '1' && this.state.orderSide == '1' ? true : false}
                  value={this.state.orderType == '1' && this.state.orderSide == '1' ? 0 : this.state.orderPrice}
                /><span className="hbar-icon">ℏ</span>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 15, position: 'relative', marginBottom: 15 }}>
                <FormLabel style={{ flex: 2 }}>Order Fee</FormLabel>
                <TextInputOutline id="orderFee" disabled={true} value={this.state.orderFee} type="number" style={{ flex: 3, maxWidth: '133px', marginLeft: '15px' }} onChange={(e) => this.handleInputChange(e.target.id, e.target.value)}></TextInputOutline>
                <FormLabel style={{ flex: 2, justifyContent: "center", marginLeft: '20px' }}>Order Reference</FormLabel>
                <TextInputOutline id="orderReference" style={{ flex: 4 }} onChange={(e) => this.handleInputChange(e.target.id, e.target.value)} />
                <span className="hbar-icon-fee">ℏ</span>
              </Row>
            </FormSendToken>
          </ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ marginRight: '20px', cursor: 'pointer', padding: '11px 110px', background: 'rgba(241, 112, 103, 1)', borderRadius: '8px', color: 'white' }} onClick={this._handleClickCloseIconButton} >Cancel</span>
            <ButtonPrimary className={isValid ? 'opacityHalf' : ''}
              style={{ marginLeft: '20px', marginBottom: 10, width: 250, color: 'white', margin: '10px auto', background: '#38b9d5' }}
              onClick={this._handleClickCreateTradeTransactionButton}
              disabled={isValid}
            >
              Create
            </ButtonPrimary>
          </div>
        </ModalFrame>
      </ModalPrimary >
    )
  }

  // trade Modal Close
  _handleClickCloseIconButton = () => {
    this.props.hideTradeTokensModal()
  }

  rightPad = (string, chars, sign) => {
    const hasPrefix = /^0x/i.test(string) || typeof string === 'number';
    string = string.toString(16).replace(/^0x/i, '');
    const padding = chars - string.length + 1 >= 0 ? chars - string.length + 1 : 0;
    return (hasPrefix ? '0x' : '') + string + new Array(padding).join(sign || '0');
  };

  // Ascii to Hex Converter
  asciiToHex = (value, length = 32) => {
    let hex = '';
    for (let i = 0; i < value.length; i++) {
      const code = value.charCodeAt(i);
      const n = code.toString(16);
      hex += n.length < 2 ? `0${n}` : n;
    }
    return '0x' + this.rightPad(hex, length * 2, 0);
  };

  // Approve token
  _handleClickTokenApprove = async (cb) => {
    const hash = (window as any).hash;
    if (hash) {
      //Token Smart Contract Address
      let tokenAddress = this.props.tokenAddress;
      let contractId = address[tokenAddress].SMART_CONTRACT;
      //Exchange Smart Contract Address
      let _spender = hash.accountIdToEthAddress(address[tokenAddress].EXCHANGE);
      let _value = this.state.orderQuantity;
      let data = {
        contractid: contractId,
        memo: "approve_sellToken",
        paymentserver: "https://mps.hashingsystems.com",
        params: `["${_spender}", "${_value}"]`,
        amount: 0,
        abi: `[{
           "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }]`,
      }
      hash.triggerSmartContract(data)
        .then(res => {
          if (res.code == 200) {
            this.setState({ isTokenApproved: true }, () => cb());
          } else {
            this.setState({ isTokenApproved: false }, () => cb());
          }
        })
        .catch(err => {
          const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
          Swal.fire({
            title: 'Error',
            text: 'Error : ' + msg,
            type: 'error',
            confirmButtonText: 'Ok',
          })
        })
    }
  }

  // Calling Trade function
  _handleClickCreateTradeTransactionButton = async () => {
    if (this.state.orderSide === '1') {
      this._handleClickTokenApprove(() => {
        this.state.isTokenApproved ?
          setTimeout(() => {
            this._tradeTransaction(true)
          }, 1000)
          : Swal.fire({
            title: 'Error',
            text: 'Token Not Approved',
            type: 'error',
            confirmButtonText: 'Ok',
          })
      });
    } else {
      this._tradeTransaction(false)
    }
  }

  // Text field validation
  _validate = () => {
    if (this.state.orderSide == this.initialState.orderSide) {
      return true
    } else if (this.state.orderType == this.initialState.orderType) {
      return true
    } else if (this.state.orderSide == '1' && this.state.orderType == '1' ? false : (this.state.orderPrice == this.initialState.orderPrice || !(Number(this.state.orderPrice) > 0))) {
      return true
    } else if (this.state.orderQuantity == this.initialState.orderQuantity || !(Number(this.state.orderQuantity) > 0)) {
      return true
    }
    return false
  }

  //Create transaction with ABI
  _tradeTransaction = (typeSell) => {
    const hash = (window as any).hash;
    if (hash && this.props.token) {
      let tokenAddress = this.props.tokenAddress;
      let contractId = address[tokenAddress].EXCHANGE;
      let version = address[tokenAddress].EXCHANGE_VERSION;
      let orderCode = this.asciiToHex(this.props.token);
      let orderSide = Number(this.state.orderSide);
      let orderType = Number(this.state.orderType);
      let orderFee = Number(this.state.orderFee);
      let orderQuantity = Number(this.state.orderQuantity);
      let orderPrice = Number(this.state.orderPrice);
      let orderReference = this.state.orderReference;
      const thisInstance = this;
      let data = {};
      if (version == 0) {
        data = {
          contractid: contractId,
          memo: typeSell ? "linkOrder_Sell" : "linkOrder_Buy",
          paymentserver: "https://mps.hashingsystems.com",
          params: `["${orderCode}", ${orderSide},${orderQuantity},${Math.round(orderPrice * 100000000)},${orderType},"${orderReference.toString()}"]`,
          amount: typeSell ? 0 : orderQuantity * Math.round(orderPrice * 100000000),
          abi: `[{
      "constant": false,
      "inputs": [
          {
              "name": "i_code",
              "type": "bytes32"
          },
          {
              "name": "i_side",
              "type": "uint8"
          },
          {
              "name": "i_quantity",
              "type": "uint256"
          },
          {
              "name": "i_price",
              "type": "uint256"
          },
          {
              "name": "i_type",
              "type": "uint8"
          },
          {
              "name": "_clientOrderID",
              "type": "string"
          }
      ],
      "name": "linkOrder",
      "outputs": [
          {
              "name": "success",
              "type": "bool"
          },
          {
              "name": "_order",
              "type": "address"
          }
      ],
      "payable": true,
      "stateMutability": "payable",
      "type": "function"
  }]`,
        }
      } else if (version == 1) {
        data = {
          contractid: contractId,
          memo: typeSell ? "linkOrder_Sell" : "linkOrder_Buy",
          paymentserver: "https://mps.hashingsystems.com",
          params: `["${orderCode}", ${orderSide},${orderQuantity},${Math.round(orderPrice * 100000000)},${Math.round(orderFee * 100000000)},${orderType},"${orderReference.toString()}"]`,
          amount: typeSell ? 0 + Math.round(orderFee * 100000000) : orderQuantity * Math.round(orderPrice * 100000000) + Math.round(orderFee * 100000000),
          abi: `[{
        "constant": false,
        "inputs": [
            {
                "name": "i_code",
                "type": "bytes32"
            },
            {
                "name": "i_side",
                "type": "uint8"
            },
            {
                "name": "i_quantity",
                "type": "uint256"
            },
            {
                "name": "i_price",
                "type": "uint256"
            },
            {
              "name": "i_fee",
              "type": "uint256"
            },
            {
                "name": "i_type",
                "type": "uint8"
            },
            {
                "name": "_clientOrderID",
                "type": "string"
            }
        ],
        "name": "linkOrder",
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            },
            {
                "name": "_order",
                "type": "address"
            }
        ],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
    }]`,
        }
      }
      hash.triggerSmartContract(data)
        .then(res => {
          if (res.code === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Order Created Successfully',
              type: 'success',
              confirmButtonText: 'Ok',
            }).then((result) => {
              thisInstance._handleClickCloseIconButton();
            });
            let orderData = {
              //orderContractAddress: hash.ethAddressToAccountId(res.result[1]),
              orderContractAddress: res.result[1],
              orderSide: this.state.orderSide,
              orderType: this.state.orderType,
              orderQuantity: this.state.orderQuantity,
              orderPrice: this.state.orderPrice,
              orderFee: this.state.orderFee,
              orderReference: this.state.orderReference,
              Status: 'NEW',
              ProcessedQuantity: 0,
              LastMatchedQuantity: 0,
              AveragePrice: 0,
              LastMatchedPrice: 0,
              tokenBalance: typeSell ? this.state.orderQuantity : 0,
              hbarBalance: typeSell ? 0 : Number(this.state.orderQuantity) * Math.round(Number(this.state.orderPrice) * 100000000),
              tokenId: this.props.token,
              tokenAddress: this.props.tokenAddress,
            }
            //TO-DO RAHUL
            saveOrdersByAccount(orderData);
          } else {
            Swal.fire({
              title: 'Error',
              text: res.message ? res.message : 'Something went wrong',
              type: 'error',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch(err => {
          const msg = err && err.responseData ? err.responseData.nodePrecheckcode : err.receiptStatus ? err.receiptStatus : err;
          Swal.fire({
            title: 'Error',
            text: 'Error : ' + msg,
            type: 'error',
            confirmButtonText: 'Ok',
          })
        })
    }
  }
}

export const SectionModalTradeTokens = connect<ISectionStoreStateProps, ISectionStoreDispatchProps, ISectionOwnProps, StoreRootState>(
  mapStateToProps,
  mapDispatchToProps
)(SectionModalTradeTokensPureComponent)